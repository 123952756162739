import React from 'react';

const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server-1/themeOneContactSection";

const CMS = () =>{
    return (
        <section id="contact" className="contact-area bg-gray ptb_100">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-6">
                    {/* Section Heading */}
                    <div className="section-heading text-center">
                    <h2 className="text-capitalize">{this.state.data.heading}</h2>
                    <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                    <p className="d-block d-sm-none mt-4">{this.state.data.headingTexttwo}</p>
                    </div>
                </div>
                </div>
                <div className="row justify-content-between">
                <div className="col-12 col-md-5">
                    {/* Contact Us */}
                    <div className="contact-us">
                    <p className="mb-3">{this.state.data.content}</p>
                        <ul>
                            {this.state.iconList.map((item, idx) => {
                                return(
                                    <li key={`ci_${idx}`} className="py-2">
                                        <a className="media" href="/#">
                                            <div className="social-icon mr-3">
                                                <i className={item.iconClass} />
                                            </div>
                                            <span className="media-body align-self-center">{item.text}</span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-6 pt-4 pt-md-0">
                </div>
                </div>
            </div>
        </section>
    );
}

export default CMS;